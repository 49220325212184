.back-button{
    position:absolute;
    left: 0px;
    top: 0px;
    color: white;
    font-family: "Rampart One";
    font-size: 30px;
    cursor: pointer;
    padding:10px;
}

.back-button:hover{
    border-bottom: 1px solid white;
    border-right: 1px solid lightgray;
}